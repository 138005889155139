import { AtomsKeys } from '../types/recoil'
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

export interface EventDate {
  date: string
  timestamp: string
}

export interface Event {
  id: string
  summary: string
  type: string
  isStudio: boolean
  duration?: number
  start?: EventDate
  end?: EventDate
  allDay?: | string
}

export type Options = {
  Address: string,
  LifeStyle: boolean,
  PhotosSup: number,
  Distance: number,
}

export type Order = {
  packageId: null | string
  backgrounds: string[]
  event: null | Event
  options: Options
  sub: null | string
  allowShareImages: boolean
  sponsorshipId: string | null
  discountCode: {
    value: null | string
    label: string
    amount: number
  }
}

export const orderDefaultValues: Order = {
  packageId: null,
  sub: null,
  options: {
    Address: '',
    Distance: 0,
    LifeStyle: false,
    PhotosSup: 0,
  },
  backgrounds: [],
  allowShareImages: false,
  event: null,
  sponsorshipId: null,
  discountCode: {
    label: '',
    value: null,
    amount: 0,
  },
}

const orderAtom = atom<Order>({
  key: AtomsKeys.ORDER,
  default: orderDefaultValues,
})

export const useOrder = () => useRecoilState(orderAtom)
export const useOrderValue = () => useRecoilValue(orderAtom)
export const useSetOrder = () => useSetRecoilState(orderAtom)
