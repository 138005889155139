import React, { useEffect } from 'react';
import { RecoilRoot } from 'recoil';
import { useSetOrder } from './src/atoms/order.atom';
import { useLocalStorage } from '@uidotdev/usehooks';
import { orderDefaultValues } from './src/atoms/order.atom';

import "@fontsource/lato/100.css";
import "@fontsource/lato/300.css";
import "@fontsource/lato/400.css";
import "@fontsource/bebas-neue";
import "@fontsource/assistant/200.css";
import "@fontsource/assistant/300.css";
import "@fontsource/assistant/400.css";
import "@fontsource/assistant/500.css";
import "@fontsource/assistant/600.css";
import "@fontsource/alex-brush/400.css";
import "@fontsource/exo-2/100.css";
import "@fontsource/exo-2/200.css";
import "@fontsource/exo-2/300.css";
import "@fontsource/exo-2/400.css";
import "@fontsource/exo-2/500.css";
import "@fontsource/exo-2/600.css";

import './src/styles/index.css'
import 'react-tooltip/dist/react-tooltip.css'

import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primereact/resources/primereact.min.css";

export const wrapRootElement = ({ element, props }) => {
  return <RecoilRoot {...props}>{element}</RecoilRoot>;
};

// New wrapper component to handle state and effects safely
const PageWrapper = ({ children, location }) => {
  const setOrder = useSetOrder();
  const [, setSponsorshipId] = useLocalStorage<string | null>('sponsorshipId', null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (location.pathname !== '/order') {
        setOrder((prevV) => {
          return {
            ...orderDefaultValues,
            packageId: prevV.packageId,
          };
        });
      }

      if (location.pathname === '/') {
        document.body.style.backgroundColor = '#F8FAFF';
        const sponsorshipId = new URLSearchParams(location.search).get('sponsorshipId');
        if (sponsorshipId) {
          setSponsorshipId(sponsorshipId);
        }
      } else if (location.pathname.match('/recrutement')) {
        document.body.style.backgroundColor = '#F8FAFF';
      } else {
        document.body.style.backgroundColor = '#FFF';
      }
    }
  }, [location.pathname]);

  return <>{children}</>;
};

// WrapPageElement to provide additional effects and local state to individual pages
export const wrapPageElement = ({ element, props }) => {
  return (
    <PageWrapper location={props.location}>
      {element}
    </PageWrapper>
  );
};
